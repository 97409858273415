

























































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import LsPagination from "@/components/ls-pagination.vue";
import LsShowPagination from "@/components/ls-pagination.vue";
import {
    cinemaBatchList,
    showBatchList,
    cinemaStatus,
    setCinemaPrice,
} from "@/api/movie";
import LsDialog from "@/components/ls-dialog.vue";
import { RequestPaging } from "@/utils/util";
import { CinemaStatus } from "@/utils/type";

@Component({
    components: {
        LsDialog,
        LsPagination,
        LsShowPagination,
    },
})
export default class CinemaPane extends Vue {
    $refs!: { paneTable: any; tableForm: any };
    city_value: any;
    status = true;
    show_details = false;
    detail_dialog = false;
    cinema_name: string = "";
    activeName = "1";
    showDate = new Date();
    cinema_id: number = 0;
    loading: Boolean = false;
    show_loading: Boolean = false;
    queryObj: any = {
        cinema_name: "",
        city_id: "",
        cinema_id: "",
    };

    selectIds: any[] = [];
    pager = new RequestPaging({ page: 1, size: 50 });
    show_pager = new RequestPaging({ page: 1, size: 10 });

    get disabledBtn() {
        return !this.selectIds.length;
    }

    handleReset() {
        this.queryObj = {
            cinema_name: "",
            city_id: this.queryObj.city_id,
            cinema_id: "",
        };
        this.getList();
    }

    //查看详情
    showDetails(cinema_id: number, cinema_name: string) {
        this.show_details = true;
        this.cinema_id = cinema_id;
        this.cinema_name = cinema_name;
        this.showDate = new Date();
        this.show_loading = true;
        // apiGoodsDetail(ids).then((res) => {
        //     this.activeName = "1";
        //     this.show_details = true;
        //     this.loading = false;
        //     this.this_details = res;
        // });
    }

    handleClose() {
        this.show_details = false;
        this.loading = false;
    }

    showList(page?: number) {
        page && (this.show_pager.page = page);
        this.show_loading = true;
        this.show_pager
            .request({
                callback: showBatchList,
                params: {
                    cinema_id: this.cinema_id,
                    show_date: this.showDate,
                },
            })
            .then((res: any) => {
                this.show_details = true;
                this.show_loading = false;
            });
    }

    handleBatchStatus(row: any) {
        row.id = this.selectIds;
        // checkGoods(row).then(() => {
        //     this.$emit("refresh");
        // });
    }

    handleSelect(val: any[]) {
        this.selectIds = val.map((item) => item.id);
    }

    // 全选商品
    handleselectAll() {
        this.$refs.paneTable.toggleAllSelection();
    }
    getList(page?: number, type?: number): void {
        page && (this.pager.page = page);
        this.loading = true;
        this.pager
            .request({
                callback: cinemaBatchList,
                params: {
                    ...this.queryObj,
                },
            })
            .then((res: any) => {
                this.loading = false;
            });
    }

    created() {
        this.city_value = this.$route.query;
        this.queryObj.city_id = this.city_value.id ? this.city_value.id : 0;
        this.city_value.id && this.getList(1);
    }
}
